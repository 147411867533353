import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { useTheme } from "styled-components";

const LoadingBar = () => {
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      #nprogress .bar {
        background-color: ${theme.colors.primary} !important;
        height:2.5px;
      }
      #nprogress .peg {
        box-shadow: 0 0 10px ${theme.colors.primary}, 0 0 5px ${theme.colors.primary} !important;
      }
    `;
    document.head.appendChild(styleElement);

    NProgress.configure({
      showSpinner: false,
      speed: 400,
      minimum: 0.2,
      innerHeight: 20,
    });

    NProgress.start();
    NProgress.done();

    return () => {
      NProgress.done();
    };
  }, [location, theme]);

  return null;
};

export default LoadingBar;
