import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./Routes";
import { useAuth } from "./stores/AuthContext";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import LoadingBar from "./components/loading_bar";
import { ThemeProvider } from "styled-components";
import ThemeContext from "./stores/ThemeContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchBarModel from "./components/search-bar-model";

const App = () => {
  const { loading, isAuthenticated } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    document.body.setAttribute("data-sidebartype", "full");
  }, []);

  const handleMenu = (e) => {
    e.preventDefault();
    if (!menuOpen) {
      document.body.setAttribute("data-sidebartype", "mini-sidebar");
      document.getElementById("main-wrapper").classList.add("show-sidebar");
      setMenuOpen(true);
    } else {
      document.body.setAttribute("data-sidebartype", "full");
      document.getElementById("main-wrapper").classList.remove("show-sidebar");
      setMenuOpen(false);
    }
  };

  if (isAuthenticated) {
    if (loading) {
      return (
        <div className="d-flex align-items-center justify-content-center vh-100 w-100">
          <div
            className="spinner-border text-primary fs-4"
            role="status"
            style={{ width: "60px", height: "60px" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <ThemeProvider theme={ThemeContext}>
          <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
            <LoadingBar />
            {/* <!-- Toastr --> */}

            <div id="main-wrapper">
              <Sidebar handleMenu={handleMenu} />
              <div className="page-wrapper">
                {/* <!--  Header Start --> */}
                <Header handleMenu={handleMenu} />
                {/* <!--  Header End --> */}

                <div className="body-wrapper">
                  <div className="container-fluid">
                    {/* <!--  Row 1 --> */}
                    <AppRoutes />
                  </div>
                </div>
              </div>

              {/* <!--  Search Bar --> */}
              <SearchBarModel />
            </div>
            <ToastContainer />
          </Router>
        </ThemeProvider>
      );
    }
  } else {
    return (
      <ThemeProvider theme={ThemeContext}>
        <div id="main-wrapper" className="auth-customizer-none">
          <div className="position-relative overflow-hidden min-vh-100 w-100 d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center w-100">
              <div className="row justify-content-center w-100">
                <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
                  <LoadingBar />
                  <AppRoutes />
                  <ToastContainer />
                </Router>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
};

export default App;
