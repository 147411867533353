import React, { useState, useCallback, useEffect } from "react";
import Announcement from "../../../components/announcement";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../../stores/AuthContext";
import { storage } from "../../../firebase";
import { Timestamp } from "firebase/firestore";
import { Link, useParams } from "react-router-dom";
import { updateEvent } from "../../../services/firebase_service";

function EditEvent() {
  const { id } = useParams();
  const { userDetails, events, setEvents } = useAuth();

  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);

  const [eventPublished, setEventPublished] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [riderLevel, setRiderLevel] = useState("");
  const [bikeType, setBikeType] = useState("Bikes & Quads");
  const [eventPrice, setEventPrice] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventDistance, setEventDistance] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventJson, setEventJson] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const [locationError, setLocationError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const eventData = events?.find((event) => event.id === id);
    if (eventData && !isInitialized) {
      setEvent(eventData);
      setEventJson(
        JSON.stringify(
          {
            EventTitle: eventData.title,
            EventLocation: eventData.location,
            RiderLevel: eventData.riderLevel,
            Distance: eventData.distance,
            StartDate: eventData.date.toDate(),
            EndDate: eventData.endDate.toDate(),
            Price: eventData.price,
            ImageUrl: eventData.imageUrl,
            BikeType: eventData.bikeType,
          },
          null,
          2
        )
      );

      setEventTitle(eventData.title || "");
      setEventLocation(eventData.location || "");
      setEventAddress(
        eventData.address != null
          ? eventData.address.toString()
          : eventData.location || ""
      );
      setEventStartDate(
        moment(eventData.date.toDate()).format("YYYY-MM-DDTHH:mm")
      );
      setEventEndDate(
        moment(eventData.endDate.toDate()).format("YYYY-MM-DDTHH:mm")
      );
      setRiderLevel(eventData.riderLevel || "");
      setBikeType(eventData.bikeType || "Bikes & Quads");
      setEventPrice(eventData.price || "");
      setEventLink(eventData.link || "");
      setEventDistance(eventData.distance || "");
      setEventDescription(eventData.description || "");
      setPreview(eventData.imageUrl || "");
      setEventPublished(eventData.published || false);

      setIsInitialized(true);
    }
  }, [id, events, isInitialized]);

  const handleDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  }, []);

  const removeFile = () => {
    setSelectedFile(null);
    setPreview(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif"],
    },
    maxFiles: 1,
  });

  const uploadImage = async () => {
    if (!selectedFile) {
      return null;
    }

    const storageRef = ref(storage, `eventImages/${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setUploading(true);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload error:", error);
          Swal.fire("Error!", "Failed to upload the image.", "error");
          setUploading(false);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadedImageUrl(downloadURL);
          setUploading(false);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let imageUrl;
      if (selectedFile) {
        imageUrl = await uploadImage();
      } else {
        imageUrl = preview;
      }
      const eventData = {
        id: id,
        price: eventPrice,
        link: eventLink,
        imageUrl: imageUrl,
        riderLevel: riderLevel,
        endDate: Timestamp.fromDate(new Date(eventEndDate)),
        bikeType: bikeType,
        start: event.start,
        title: eventTitle,
        date: Timestamp.fromDate(new Date(eventStartDate)),
        published: eventPublished,
        featured: event.featured,
        description: eventDescription,
        location: eventLocation,
        address: eventAddress,
        distance: eventDistance,
        createdBy: event.createdBy || userDetails.uid,
        createdAt: event.createdAt || Timestamp.now(),
        updatedAt: Timestamp.now(),
        updatedby: userDetails.uid,
      };

      await updateEvent(id, eventData);

      const updatedEvents = events.filter((event) => event.id !== id);
      updatedEvents.push(eventData);
      // Update the events in the AuthContext
      setEvents(updatedEvents);
      setLoading(false);
      toast.success("Event saved successfully!");
    } catch (error) {
      setLoading(false);
      toast.error(`Error saving event: ${error}`);
      console.error("Error saving event:", error);
    }
  };

  const handlePlaceSelected = (value) => {
    const placeId = value.value.place_id;

    geocodeByPlaceId(placeId)
      .then((results) => {
        // Extract address components
        const addressComponents = results[0].address_components;

        // Find suburb (locality or sublocality)
        const suburb = addressComponents.find(
          (component) =>
            component.types.includes("sublocality") ||
            component.types.includes("locality")
        )?.long_name;

        // Find province (administrative_area_level_1)
        const province = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name;

        // You can now set these values in your state if needed
        if (suburb && province) {
          setEventLocation(`${suburb}, ${province}`);
          setEventAddress(results[0].formatted_address);
        } else {
          setLocationError(
            "The location you selected is not valid. Please search for a valid location."
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const processJson = () => {
    // check of the JSON is valid
    try {
      const jsonData = JSON.parse(eventJson);

      const startDate = moment(jsonData.StartDate).format("YYYY-MM-DDTHH:mm");
      jsonData.StartDate = startDate;
      const endDate = moment(jsonData.EndDate).format("YYYY-MM-DDTHH:mm");
      jsonData.EndDate = endDate;
      console.log(jsonData);

      setEventTitle(jsonData.EventTitle);
      setEventStartDate(jsonData.StartDate);
      setEventEndDate(jsonData.EndDate);
      setEventLocation(jsonData.EventLocation);
      setRiderLevel(jsonData.RiderLevel);
      setEventDistance(jsonData.Distance);
      setBikeType(jsonData.BikeType);
      setEventPrice(jsonData.Price);
      setEventLink(jsonData.EventLink);

      if (jsonData.ImageUrl) {
        const imageUrl = jsonData.ImageUrl;
        fetch(imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "tempImage.jpg", { type: blob.type });
            handleDrop([file]);
          })
          .catch((error) => console.error("Error downloading image:", error));
      }
    } catch (error) {
      toast.error(`Error!, Invalid JSON. ${error}`);
      return;
    }
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Edit Event</h4>
                  <p className="mb-0">Add your event details below</p>
                </div>
                <div className="right">
                  <Link to="/events" className="btn btn-primary">
                    <i className="ti ti-arrow-left"></i> Back to Events
                  </Link>
                </div>
              </div>
            </div>
            {event && (<form id="editEventForm" onSubmit={handleSubmit}>
              <div className="card-body pt-0 row">
                <div className="col-xl-8">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventTitle" className="form-label">
                          Event Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="eventTitle"
                          name="eventTitle"
                          value={eventTitle}
                          onChange={(e) => setEventTitle(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventStartDate" className="form-label">
                          Event Start <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="eventStartDate"
                          name="eventStartDate"
                          value={eventStartDate}
                          onChange={(e) => setEventStartDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventLocation" className="form-label">
                          Event Location <span className="text-danger">*</span>
                        </label>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyD_Lsht21cw3UEhTUP9kPW0R9kylZIqCmM"
                          selectProps={{
                            className:
                              "locationAutoComplete" +
                              (locationError ? " is-invalid" : ""),
                            initialValue: eventLocation,
                            onChange: handlePlaceSelected,
                            placeholder: "Search for a location",
                            onFocus: (e) => {
                              e.target.value = "";
                              setLocationError(null);
                            },
                            styles: {
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#7c8fac",
                              }),
                              control: (provided) => ({
                                ...provided,
                                background: "transparent",
                                border: "1px solid #333f55",
                                borderRadius: "5px",
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: "#fff",
                                cursor: "text",
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: "#fff",
                                background: "#0F1820",
                                cursor: "pointer",
                              }),
                              dropdownIndicator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                background: "#0F1820",
                              }),
                              loadingIndicator: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              loadingMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              noOptionsMessage: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                            },
                          }}
                          apiOptions={{
                            region: "za",
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["za"],
                            },
                          }}
                          style={{ background: "#ff0000" }}
                        />
                        {locationError ? (
                          <div className="invalid-feedback">
                            {locationError}
                          </div>
                        ) : null}
                        {eventLocation ? (
                          <small className="text-muted">{eventLocation}</small>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventEndDate" className="form-label">
                          Event End <span className="text-danger">*</span>
                        </label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          id="eventEndDate"
                          name="eventEndDate"
                          value={eventEndDate}
                          onChange={(e) => setEventEndDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="bikeType" className="form-label">
                          Bike Type <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          name="bikeType"
                          id="bikeType"
                          value={bikeType}
                          onChange={(e) => setBikeType(e.target.value)}
                        >
                          <option value="Bikes & Quads">Bikes & Quads</option>
                          <option value="Bikes Only">Bikes Only</option>
                          <option value="Quads Only">Quads Only</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="riderLevel" className="form-label">
                          Rider Level
                        </label>
                        <select
                          className="form-select"
                          name="riderLevel"
                          id="riderLevel"
                          value={riderLevel}
                          onChange={(e) => setRiderLevel(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="Beginner">Beginner</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Advanced">Advanced</option>
                          <option value="Expert">Expert</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventDistance" className="form-label">
                          Event Distance
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="eventDistance"
                          name="eventDistance"
                          value={eventDistance}
                          onChange={(e) => setEventDistance(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="eventPrice" className="form-label">
                          Event Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="eventPrice"
                          name="eventPrice"
                          value={eventPrice}
                          onChange={(e) => setEventPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label htmlFor="eventLink" className="form-label">
                          Event Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="eventLink"
                          name="eventLink"
                          defaultValue={eventLink}
                          onChange={(e) =>
                            setEventLink(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label
                          htmlFor="eventDescription"
                          className="form-label"
                        >
                          Event Description
                        </label>
                        <textarea
                          className="form-control"
                          id="eventDescription"
                          rows="15"
                          value={eventDescription}
                          onChange={(e) => setEventDescription(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="mb-3">
                    <label className="form-label">
                      Event Image <span className="text-danger">*</span>
                    </label>
                    <div
                      {...getRootProps({
                        className: "dropzone border rounded-3 p-3 text-center",
                      })}
                    >
                      <input {...getInputProps()} />
                      {preview ? (
                        <div>
                          <img
                            src={preview}
                            alt="Preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "150px",
                              marginBottom: "10px",
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={removeFile}
                          >
                            Remove File
                          </button>
                        </div>
                      ) : (
                        <p className="mb-0">
                          Drag an image here, or click to upload
                        </p>
                      )}
                    </div>
                  </div>

                  {userDetails?.role === "admin" ? (
                    <div className="mb-3 d-flex justify-content-end flex-column">
                      <label htmlFor="eventJson" className="form-label">
                        Event JSON
                      </label>
                      <textarea
                        className="form-control"
                        id="eventJson"
                        rows="10"
                        value={eventJson}
                        onChange={(e) => setEventJson(e.target.value)}
                      />
                      <button
                        className="btn btn-grey mt-3"
                        type="button"
                        onClick={processJson}
                      >
                        Parse JSON
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end">
                <div className="form-check form-switch py-2 me-4">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    id="eventPublished"
                    defaultChecked={eventPublished}
                    onChange={(e) => setEventPublished(!eventPublished)}
                  />
                  <label className="form-check-label" htmlFor="eventPublished">
                    Publish
                  </label>
                </div>
                <button type="submit" className="btn btn-primary d-flex">
                  {uploading || loading ? (
                    <div className="spin me-2">
                      <i className="ti ti-loader"></i>
                    </div>
                  ) : null}
                  Save Event
                </button>
              </div>
            </form>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEvent;
