import React, { useEffect, useState } from "react";
import { useAuth } from "../../../stores/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "./events.css";
import Announcement from "../../../components/announcement";
import { Tooltip } from "react-tooltip";
import { updateEvent } from "../../../services/firebase_service";
import { toast } from "react-toastify";

DataTable.use(DT);

function Events() {
  const { events, user, setEvents } = useAuth();
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState({});

  const tableOptions = {
    order: [[5, "desc"]],
    paging: true,
    searching: true,
    lengthMenu: [10, 25, 50, 75, 100],
    language: {
      emptyTable: "No events available to display",
    },
    columnDefs: [
      {
        targets: [0, 1],
        visible: false,
      },
      {
        targets: [2], // Column for the event title
        render: function (data, type, row) {
          return data
            ? `<div class="d-flex"><img src="${
                row[1]
              }" class="rounded-circle cursor-pointer me-2 object-fit-cover" width="40" height="40" onClick="navigateToEvent('${
                row[0]
              }')" /><div class="desc"><span class="text-primary text-body fw-bold underLink" style="cursor:pointer;" onClick="navigateToEvent('${
                row[0]
              }')">${data.split(",")[0]}</span><br/><small class="text-muted">${
                data.split(",")[1]
              }</small></div></div>`
            : ``;
        },
      },
      {
        targets: [5],
        render: function (data, type, row) {
          if (type === "sort" || type === "type") {
            return new Date(data.split(" ").join(" ")).getTime();
          }
          return `<span class="text-body fw-bold">${data}</span>`;
        },
      },
      {
        targets: [7],
        render: function (data, type, row) {
          return `<div class="form-check form-switch py-2">
                    <input class="form-check-input cursor-pointer" type="checkbox" id="status${
                      row[0]
                    }" ${
            data ? "checked" : ""
          } onChange="window.updateStatus('${row[0]}')">
                  </div>`;
        },
      },
      {
        targets: [8],
        sortable: false,
        render: function (data, type, row) {
          return `<a class="dropdown-item d-flex align-items-center gap-3 cursor-pointer" onClick="editEvent('${row[0]}')"><i class="fs-6 ti ti-edit"></i></a>`;
        },
      },
    ],
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (events) {
      setFilteredEvents(events);
      setLoading(false);
    }
  }, [events]);

  useEffect(() => {
    setTableData(
      filteredEvents.map((event) => {
        const eventContent = event.description.replace(/<\/?[^>]+(>|$)/g, "");

        return [
          event.id,
          event.imageUrl ?? "https://via.placeholder.com/150",
          `${event.title},${eventContent.substring(0, 50)}` ?? "",
          event.riderLevel ?? "",
          event.distance ?? "",
          event.date
            ? new Date(event.date.seconds * 1000).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
            : "",
          event.price ?? "",
          event.published ?? "",
          event.id,
        ];
      })
    );
    console.log("filteredEvents", filteredEvents);
  }, [filteredEvents]);

  useEffect(() => {
    window.updateStatus = async (eventId) => {
      const event = events.find((event) => event.id === eventId);
      event.published = !event.published;

      try {
        await updateEvent(eventId, event);
        const updatedEvents = events.map((e) => (e.id === eventId ? event : e));
        setEvents(updatedEvents);
        if (event.published) {
          toast.success("Event published!");
        } else {
          toast.success("Event unpublished!");
        }
      } catch (error) {
        toast.error("Error: Unable to update event status");
        console.error("Error updating event status", error);
      }
    };

    window.editEvent = (eventId) => {
      navigate(`/event/${eventId}/edit`);
    };

    window.navigateToEvent = (eventId) => {
      window.open(`https://share.funduro.app/event/${eventId}`, '_blank');
    };

    // Clean up the functions when the component unmounts
    return () => {
      delete window.navigateToEvent;
      delete window.updateStatus;
      delete window.editEvent;
    };
  }, [navigate, events, setEvents]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Events</h4>
                  <p className="mb-0">Manage and view all registered events</p>
                </div>
                <div className="right">
                  <Tooltip id="tooltopBottom" place="left" />
                  {!user.emailVerified ? (
                    <div
                      data-tooltip-id="tooltopBottom"
                      data-tooltip-content="You need to verify your email address first"
                      className="cursor-not-allowed"
                    >
                      <button className="btn btn-primary" disabled>
                        <i className="ti ti-plus"></i> New
                      </button>
                    </div>
                  ) : (
                    <Link to="/event/new" className="btn btn-primary">
                      <i className="ti ti-plus"></i> New
                    </Link>
                  )}
                  <Tooltip id="disabledBtn" />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading ? (
                  <DataTable
                    className="table text-nowrap usersTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={tableData}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">ID</th>
                        <th className="fs-4 fw-semibold mb-0">Image</th>
                        <th className="fs-4 fw-semibold mb-0">Title</th>
                        <th className="fs-4 fw-semibold mb-0">Rider Level</th>
                        <th className="fs-4 fw-semibold mb-0">Distance</th>
                        <th className="fs-4 fw-semibold mb-0">Date</th>
                        <th className="fs-4 fw-semibold mb-0">Price</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
