import React from "react";
import { useAuth } from "../stores/AuthContext";
import { toast } from "react-toastify";

function Announcement() {
  const { resendEmailVerification, user } = useAuth();

  const sendEmailVerification = async () => {
    try {
      await resendEmailVerification();
      toast.success("Email verification resent successfully");
    } catch (error) {
      toast.error("Error sending email verification");
    }
  };

  return (
    <div className="mb-4">
      {!user.emailVerified && (
        <div className="alert alert-warning show mb-0">
          <i className="ti ti-alert-triangle"></i>
          <strong>Warning!</strong> Before you can add events you need to verify
          your email address.
          <button
            className="btn btn-sm btn-link text-primary"
            onClick={sendEmailVerification}
          >
            Click here to resend.
          </button>
        </div>
      )}
    </div>
  );
}

export default Announcement;
