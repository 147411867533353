// --primary-color: #F6BE01;
//   --secondary-color: #0F1820;
//   --light-background-color: #FFFFFF;
//   --dark-background-color: #0F1820;
//   --text-color: #000000;
const ThemeContext = {
  colors: {
    primary: "#F6BE01",
    secondary: "#0F1820",
    lightBackground: "#FFFFFF",
    darkBackground: "#0F1820",
    text: "#ffffff",
  },
  typography: {
    fontFamily: '"Arial", sans-serif',
    fontSize: "16px",
    fontWeight: "400",
  },
  spacing: (factor) => `${factor * 8}px`, // Multiplier function for consistent spacing
};

export default ThemeContext;
