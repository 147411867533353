import React, { useEffect, useState } from "react";
import { useAuth } from "../../../stores/AuthContext";
import { Link, useParams } from "react-router-dom";
import { capitalize } from "../../../utils/stringUtils";
import { Tooltip } from "react-tooltip";
import Announcement from "../../../components/announcement";

function User() {
  const { id } = useParams();
  const { users } = useAuth();

  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusClass, setStatusClass] = useState(null);

  useEffect(() => {
    const user = users.find((user) => user.uid === id);
    setUser(user);

    const lastLoginDate = user?.lastLogin?.seconds
      ? new Date(user?.lastLogin.seconds * 1000)
      : null;
    const createdAtDate = user?.createdAt?.seconds
      ? new Date(user?.createdAt.seconds * 1000)
      : null;

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    if (lastLoginDate) {
      if (lastLoginDate < sixMonthsAgo) {
        setStatus("Lost");
      } else if (lastLoginDate < threeMonthsAgo) {
        setStatus("Inactive");
      }
    } else if (createdAtDate && createdAtDate < threeMonthsAgo) {
      setStatus("Lost");
    } else {
      setStatus("Active");
    }

    if (status === "Active") {
      setStatusClass("bg-success-subtle text-success");
    } else if (status === "Inactive") {
      setStatusClass("bg-warning-subtle text-warning");
    } else {
      setStatusClass("bg-danger-subtle text-danger");
    }
  }, [users]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="d-flex mb-3">
            <Link to="/users" className="btn btn-primary">
              <i className="ti ti-arrow-left"></i> Back
            </Link>
          </div>
          <div className="card overflow-hidden">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-2">
                  <div className="m-0">
                    <div className="d-flex mb-2">
                      <div className="d-flex align-items-center round-110">
                        <div className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden round-100">
                          <img
                            src={
                              user?.photoURL ??
                              `https://ui-avatars.com/api/?name=${user?.firstName}+${user?.lastName}&background=random&color=fff`
                            }
                            alt="modernize-img"
                            className="w-100 h-100"
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-left">
                      <h5 className="mb-0">
                        {user?.firstName} {user?.lastName}{" "}
                        <div className={`badge ${statusClass}`}>
                          {capitalize(status)}
                        </div>
                      </h5>
                      <p className="mb-3">
                        {user != null && user.role == "admin" ? (
                          <i className="ti ti-shield-lock text-primary fs-4"></i>
                        ) : (
                          ""
                        )}
                        {user != null && user.role != null
                          ? capitalize(user.role)
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <h4 className="mb-3">Profile Details</h4>
                  <div className="vstack gap-3 mt-4">
                    <div className="hstack gap-6">
                      <i className="ti ti-mail text-dark fs-6"></i>
                      <h6 className=" mb-0">
                        <a
                          href={`mailto:${user?.email}`}
                          target="_blank"
                          className="underLink"
                        >
                          {user?.email}
                        </a>
                      </h6>
                    </div>
                    {user?.phoneNumber?.number ? (
                      <div className="hstack gap-6">
                        <i className="ti ti-device-mobile text-dark fs-6"></i>
                        <h6 className=" mb-0">
                          <a
                            className="underLink"
                            href={`tel:+${user?.phoneNumber?.countryDialCode}${user?.phoneNumber?.number}`}
                          >
                            0{user?.phoneNumber?.number}
                          </a>
                        </h6>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-5">
                  <h4 className="mb-3">Settings</h4>
                  <Tooltip id="detailTooltip" place="right" />
                  <div className="vstack gap-3 mt-4">
                    <div className="hstack gap-6">
                      <i className="ti ti-gradienter text-dark fs-6"></i>
                      <h6
                        className="mb-0"
                        data-tooltip-id="detailTooltip"
                        data-tooltip-content="Marketplace preferred radius"
                      >
                        {user?.settings?.preferedDistance} KMs
                      </h6>
                    </div>
                    {user?.phoneNumber?.number ? (
                      <div className="hstack gap-6">
                        <i className="ti ti-map-pin text-dark fs-6"></i>
                        <h6
                          className="mb-0"
                          data-tooltip-id="detailTooltip"
                          data-tooltip-content="Events preferred province"
                        >
                          {user?.settings?.preferedProvince}
                        </h6>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {user?.bikes != null ? (
              <div className="col-lg-4">
                <div className="card shadow-none border">
                  <div className="card-header bg-transparent d-flex justify-content-between align-items-center">
                    <h4>Garage</h4>{" "}
                    <Link to="/garage/add" className="btn btn-sm btn-primary">
                      <i className="ti ti-plus"></i> Add
                    </Link>
                  </div>
                  <div className="card-body pt-2">
                    <div className="vstack gap-3">
                      {user?.bikes != null
                        ? user?.bikes?.map((bike) => (
                            <div className="hstack gap-6">
                              <div className="border border-4 border-white d-flex align-items-center justify-content-center rounded-circle overflow-hidden round-50 p-1 bg-white">
                                <img
                                  src={`https://firebasestorage.googleapis.com/v0/b/funduro-za.appspot.com/o/brandLogos%2F${bike?.make?.toLowerCase()}.jpg?alt=media&token=5c9a84ba-d12a-4500-8331-67295613e908`}
                                  alt={`${bike?.make} logo`}
                                  className="w-100 h-100 rounded"
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                              <h6 className="mb-0">
                                {bike?.year} {bike?.make?.toUpperCase()}{" "}
                                {bike?.model?.toUpperCase()}
                              </h6>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="col-lg-8">
              <div className="card shadow-none border">
                <div className="card-header bg-transparent d-flex justify-content-between align-items-center">
                  <h4>Favourite Events</h4>
                </div>
                <div className="card-body pt-2">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="card hover-img overflow-hidden">
                        <div className="card-body p-0">
                          <img
                            src="/assets/images/products/s1.jpg"
                            alt="modernize-img"
                            height="220"
                            className="w-100 object-fit-cover"
                          />
                          <div className="p-4 d-flex align-items-center justify-content-between">
                            <div>
                              <h6 className="mb-0">Isuava wakceajo fe.jpg</h6>
                              <span className="text-dark fs-2">
                                Wed, Dec 14, 2024
                              </span>
                            </div>
                            <div className="dropdown">
                              <a
                                className="text-muted fw-semibold d-flex align-items-center p-1"
                                href="javascript:void(0)"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="ti ti-dots-vertical"></i>
                              </a>
                              <ul className="dropdown-menu overflow-hidden">
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0)"
                                  >
                                    Isuava wakceajo fe.jpg
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
