import { db } from "../firebase";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

export const addEvent = (event) => {
  return addDoc(collection(db, "events"), event);
};

export const updateEvent = (eventId, event) => {
  return setDoc(doc(db, "events", eventId), event);
};

export const updateUser = async (userId, userData) => {
  try {
    const userRef = doc(db, "users", userId);
    const updateData = {
      ...userData,
      updatedAt: serverTimestamp(),
    };
    delete updateData.uid; // Remove uid from update data
    await updateDoc(userRef, updateData);
    return true;
  } catch (error) {
    console.error("Error updating user:", error);
    throw new Error("Permission denied: Unable to update user data");
  }
};

export const updateUserLoginStatus = async (userId, status) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      status: status,
      updatedAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error("Error updating user login status:", error);
    throw new Error("Permission denied: Unable to update user status");
  }
};

export const updateProduct = async (productId, productData, user) => {
  const productRef = doc(db, "products", productId);
  const updateData = {
    ...productData,
    updatedAt: serverTimestamp(),
    updatedBy: user.id,
  };
  return await updateDoc(productRef, updateData);
};
