import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../stores/AuthContext";
import Announcement from "../../../components/announcement";

function EditUser() {
  const id = useParams().id;
  const { users } = useAuth();
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = users.find((user) => user.id === id);
    console.log("User:", user);
    setUser(user);
  }, [id, users]);

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent d-flex align-items-center justify-content-between">
              <div className="left">
                <h4 className="card-title">Edit {user?.firstName} {user?.lastName}</h4>
              </div>
              <div className="right">
                <Link to="/users" className="btn btn-primary">
                  <i className="ti ti-arrow-left"></i> Back to Users
                </Link>
              </div>
            </div>
            <div className="card-body pt-0"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
