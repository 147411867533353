import React, { useEffect, useState } from "react";
import { useAuth } from "../../../stores/AuthContext";
import Announcement from "../../../components/announcement";
import { Tooltip } from "react-tooltip";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import moment from "moment";
import { render } from "nprogress";
import { capitalize } from "../../../utils/stringUtils";

DataTable.use(DT);

function Marketplace() {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { products, user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [productsTableData, setProductsTableData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (products && products.length > 0) {
      console.log("products", products);
      setFilteredProducts(products);
    }
    setLoading(false);

    window.navigateToProduct = (productId) => {
      window.open(`https://share.funduro.app/product/${productId}`, "_blank");
    };

    window.editProduct = (productId) => {
      navigate(`/product/${productId}/edit`);
    };

    window.deleteProduct = (productId) => {
      console.log("Delete product", productId);
    };

    window.reviewWithGpt = (productId) => {
      console.log("Review with GPT", productId);
    };

    // Clean up the functions when the component unmounts
    return () => {
      delete window.navigateToProduct;
      delete window.editProduct;
      delete window.deleteProduct;
      delete window.reviewWithGpt;
    };
  }, [products]);

  useEffect(() => {
    if (filteredProducts && filteredProducts.length > 0) {
      setProductsTableData(
        filteredProducts.map((product) => {
          return [
            product.id,
            product.images[0],
            product.itemName,
            product.price,
            product.category,
            product.status,
            product.timestamp,
            product.id,
          ];
        })
      );
    }
  }, [filteredProducts]);

  const tableOptions = {
    order: [[6, "desc"]],
    paging: true,
    searching: true,
    lengthMenu: [5, 10, 25, 50, 75, 100],
    length: 6,
    language: {
      emptyTable: "No products available to display",
    },
    columnDefs: [
      {
        targets: [0, 1],
        visible: false,
      },
      {
        targets: [2],
        render: function (data, type, row) {
          return data
            ? `<div class="d-flex align-items-center" onClick="navigateToProduct('${row[0]}')"><img src="${row[1]}" class="cursor-pointer me-2 object-fit-cover" width="80" height="80" /><div class="desc"><span class="text-primary text-body fw-bold underLink" style="cursor:pointer;">${data}</span><br/><small class="text-muted">${row[4]}</small></div></div>`
            : ``;
        },
      },
      {
        targets: [3],
        render: function (data, type, row) {
          return data
            ? `R ${parseInt(data).toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`
            : "";
        },
      },
      {
        targets: [5],
        render: function (data, type, row) {
          if (data === "approved") {
            return `<span class="badge bg-success-subtle text-success">${capitalize(
              data
            )}</span>`;
          } else if (data === "pending") {
            return `<span class="badge bg-warning-subtle text-warning">${capitalize(
              data
            )}</span>`;
          } else {
            return `<span class="badge bg-danger-subtle text-danger">${capitalize(
              data
            )}</span>`;
          }
        },
      },
      {
        targets: [6],
        render: function (data, type, row) {
          if (type === "sort" || type === "type") {
            return data.toDate().getTime();
          }
          return `<span class="text-body fw-bold">${moment(
            data.toDate()
          ).format("DD MMM YYYY")}</span>`;
        },
      },
      {
        targets: [7],
        sortable: false,
        render: function (data, type, row) {
          return `
            <div class="dropdown dropstart">
                <a href="#" class="text-muted" id="dropdownMenuButton-${data}" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ti ti-dots-vertical fs-6"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton-${data}">
                    <li>
                        <button class="dropdown-item d-flex align-items-center gap-3" onClick="reviewWithGpt('${data}')">
                            <i class="fs-4 ti ti-robot"></i>Review with GPT
                        </button>
                    </li>
                    <li>
                        <button class="dropdown-item d-flex align-items-center gap-3" onClick="editProduct('${data}')">
                            <i class="fs-4 ti ti-edit"></i>Edit
                        </button>
                    </li>
                    <li>
                        <button class="dropdown-item d-flex align-items-center gap-3" onClick="deleteProduct('${data}')">
                            <i class="fs-4 ti ti-trash"></i>Delete
                        </button>
                    </li>
                </ul>
            </div>`;
        },
      },
    ],
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="bg-transparent d-flex align-items-center justify-content-between mb-3">
                <div className="left">
                  <h4 className="">Marketplace</h4>
                  <p className="mb-0">Manage and view all your products</p>
                </div>
                <div className="right">
                  <Tooltip id="tooltopBottom" place="left" />
                  {!user.emailVerified ? (
                    <div
                      data-tooltip-id="tooltopBottom"
                      data-tooltip-content="You need to verify your email address first"
                      className="cursor-not-allowed"
                    >
                      <button className="btn btn-primary" disabled>
                        <i className="ti ti-plus"></i> New
                      </button>
                    </div>
                  ) : (
                    <Link to="/event/new" className="btn btn-primary">
                      <i className="ti ti-plus"></i> New
                    </Link>
                  )}
                  <Tooltip id="disabledBtn" />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive rounded-4" data-bs-theme="dark">
                {!loading &&
                productsTableData &&
                productsTableData.length > 0 ? (
                  <DataTable
                    className="table text-nowrap usersTable table-dark mb-0 align-middle"
                    options={tableOptions}
                    data={productsTableData}
                  >
                    <thead>
                      <tr>
                        <th className="fs-4 fw-semibold mb-0">ID</th>
                        <th className="fs-4 fw-semibold mb-0">Image</th>
                        <th className="fs-4 fw-semibold mb-0">Title</th>
                        <th className="fs-4 fw-semibold mb-0">Price</th>
                        <th className="fs-4 fw-semibold mb-0">Category</th>
                        <th className="fs-4 fw-semibold mb-0">Status</th>
                        <th className="fs-4 fw-semibold mb-0">Date</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                  </DataTable>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marketplace;
