import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import { auth } from "../firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  browserSessionPersistence
} from "firebase/auth";
import { db } from "../firebase";
import { getDocs, collection, doc, setDoc } from "firebase/firestore";

// Create a context for auth state
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [products, setProducts] = useState([]);
  const [settings, setSettings] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [supportRequests, setSupportRequests] = useState([]);

  // Memoize initializeUser function
  const initializeUser = useCallback(async (user) => {
    if (user) {
      setUser({ ...user });
      setIsAuthenticated(true);
      await getCurrentUserDetails(user.uid);
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, []); // Empty dependency array since it doesn't depend on any state

  // Modified useEffect for auth state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return () => unsubscribe();
  }, [initializeUser]);

  // Modified useEffect for fetching collections
  useEffect(() => {
    if (!userDetails || loading) return;

    const fetchData = async () => {
      if (userDetails.id && userDetails.role === "admin") {
        await Promise.all([
          fetchCollection("users", setUsers),
          fetchCollection("events", setEvents),
          fetchCollection("products", setProducts),
          fetchCollection("support_requests", setSupportRequests)
        ]);
      } else {
        await Promise.all([
          fetchCollectionByUser("users", setUsers, userDetails.id),
          fetchCollectionByUser("events", setEvents, userDetails.id),
          fetchCollectionByUser("products", setProducts, userDetails.id),
          fetchCollectionByUser("support_requests", setSupportRequests, userDetails.uid)
        ]);
      }

      await Promise.all([
        fetchCollection("categories", setCategories),
        fetchCollection("notifications", setNotifications),
        fetchCollection("settings", setSettings),
        fetchCollection("sponsors", setSponsors)
      ]);
    };

    fetchData();
  }, [userDetails, loading]); // Only depend on userDetails and loading

  const fetchCollection = async (collectionName, setState) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setState(data);
      console.log(`${collectionName} fetched successfully:`, data);
    } catch (error) {
      console.error(`Error fetching ${collectionName} collection:`, error);
    }
  };

  const fetchCollectionByUser = async (collectionName, setState, userId) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const filteredData = data.filter((item) => item.createdBy === userId);
      setState(filteredData);
      // console.log(`${collectionName} fetched successfully:`, data);
    } catch (error) {
      // console.error(`Error fetching ${collectionName} collection:`, error);
    }
  };

  const getCurrentUserDetails = async (userId) => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const currentUser = data.filter((item) => item.id === userId);
      setUserDetails(currentUser[0]);
      // console.log("Current user:", currentUser[0]);
      // console.log(
      //   "=========================================================================================="
      // );
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  // Login function using Firebase Authentication
  const login = (email, password, rememberMe) => {
    if (!rememberMe) {
      browserSessionPersistence.type = 'SESSION';
      auth.setPersistence(browserSessionPersistence);
    }
    return signInWithEmailAndPassword(auth, email, password);
  };

  const resendEmailVerification = async () => {
    return sendEmailVerification(auth.currentUser);
  }

  // Register function using Firebase Authentication
  const register = async (email, password, organiserName) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Send email verification
      await sendEmailVerification(userCredential.user);

      // Create a record in the users collection with the user's email and role
      const userData = {
        firstName: organiserName,
        lastName: "",
        email: email,
        role: "organiser",
        status: "pending",
        createdAt: new Date(),
        aIAssistantId: null,
        gender: null,
        phone: null,
        lastLogin: null,
        userPlan: 'free',
        photoUrl: null,
        phoneNumberVerified: false,
        uid: userCredential.user.uid,
      };
      await setDoc(doc(db, "users", userCredential.user.uid), userData);
      setUserDetails(userData);

      // Return a success message
      return { success: true, message: "Your account has been created, please verify your email address by click on the link sent to your email address." };
    } catch (error) {
      // Handle any errors
      console.error(error);
      return { success: false, message: error.message };
    }
  };

  // Reset password function using Firebase Authentication
  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  // Logout function using Firebase Authentication
  const logout = () => {
    return signOut(auth);
  };

  const value = {
    user,
    isAuthenticated,
    loading, 
    login,
    register,
    resetPassword,
    logout,
    users,
    categories,
    events,
    setEvents,
    notifications,
    products,
    settings,
    sponsors,
    supportRequests,
    userDetails,
    resendEmailVerification,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
