import React, { useEffect, useState } from "react";
import { useAuth } from "../../../stores/AuthContext";
import { capitalize } from "../../../utils/stringUtils";
import { formatDistanceToNow } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import "./users.css";
import Announcement from "../../../components/announcement";

function Users() {
  const { users } = useAuth();
  const [loading, setLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredProvince, setFilteredProvince] = useState(null);
  const [filteredGender, setFilteredGender] = useState(null);
  const [filteredRole, setFilteredRole] = useState(null);
  const [filteredPlan, setFilteredPlan] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (users) {
      applyFilters();
      setLoading(false);
    }
  }, [
    users,
    filteredProvince,
    filteredGender,
    filteredRole,
    searchQuery,
  ]);

  const handleRowClick = (id) => {
    navigate(`/user/${id}`);
  };

  const applyFilters = () => {
    let filtered = [...users];

    if (filteredProvince) {
      filtered = filtered.filter(
        (user) => user.settings?.preferedProvince === filteredProvince
      );
    }

    if (filteredGender) {
      filtered = filtered.filter(
        (user) => user.gender?.toLowerCase() === filteredGender.toLowerCase()
      );
    }

    if (filteredRole) {
      filtered = filtered.filter(
        (user) => user.role?.toLowerCase() === filteredRole.toLowerCase()
      );
    }

    if (filteredPlan) {
      if (filteredPlan === null || filteredPlan === undefined) {
        filteredPlan = "free";
      }
      filtered = filtered.filter(
        (user) =>
          (user.userPlan?.toLowerCase() || "free") ===
          filteredPlan.toLowerCase()
      );
    }

    if (searchQuery.trim() !== "") {
      filtered = filtered.filter((user) =>
        `${user.firstName} ${user.lastName} ${user.email} ${user?.gender} ${user?.settings?.preferedProvince} ${user?.userPlan}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    setFilteredUsers(filtered);
  };

  const filterUsersByProvince = (province) => {
    setFilteredProvince(province);
  };

  const filterByGender = (gender) => {
    setFilteredGender(gender);
  };

  const filterByRole = (role) => {
    setFilteredRole(role);
  };

  const filterByPlan = (plan) => {
    setFilteredPlan(plan);
  };

  const removeProvinceFilter = () => {
    setFilteredProvince(null);
  };

  const removeRoleFilter = () => {
    setFilteredRole(null);
  };

  const removeGenderFilter = () => {
    setFilteredGender(null);
  };

  const removePlanFilter = () => {
    setFilteredPlan(null);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <Announcement />
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header bg-transparent d-flex align-items-center justify-content-between">
              <div className="left">
                <h4 className="card-title">Users ({filteredUsers.length})</h4>
                <p className="card-subtitle">
                  Manage and view all registered users
                </p>
              </div>
              <div className="right">
                <Link to="/users/new" className="btn btn-primary">
                  <i className="ti ti-plus"></i> New
                </Link>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-md-10">
                  <div className="d-flex justify-content-start align-items-center mb-3">
                    {filteredProvince && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Province: {filteredProvince}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removeProvinceFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredGender && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Gender: {capitalize(filteredGender)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removeGenderFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredRole && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Role: {capitalize(filteredRole)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removeRoleFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                    {filteredPlan && (
                      <span className="mb-1 me-1 badge text-bg-dark">
                        Plan: {capitalize(filteredPlan)}{" "}
                        <button
                          className="btn btn-sm"
                          onClick={removePlanFilter}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="input-group mb-3">
                    <input
                      type="search"
                      className="form-control searchInput"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
              <div
                className="table-responsive border rounded-4"
                data-bs-theme="dark"
              >
                {!loading ? (
                  <table className="table text-nowrap usersTable table-bordered table-dark mb-0 align-middle">
                    <thead className="text-dark fs-4">
                      <tr>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Full Name</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Role</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Status</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Gender</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">
                            Preferred Province
                          </h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Last Login</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Signed Up</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">Plan</h6>
                        </th>
                        <th>
                          <h6 className="fs-4 fw-semibold mb-0">&nbsp;</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((user, index) => (
                        <tr key={`${index}User${user.uid}`}>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  user.photoURL ??
                                  `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&background=random&color=fff`
                                }
                                className="rounded-circle cursor-pointer"
                                width="40"
                                height="40"
                                onClick={() => handleRowClick(user.uid)}
                                alt={`${user.firstName}+${user.lastName}`}
                              />
                              <div className="ms-3">
                                <h6
                                  className="fs-4 fw-semibold mb-0 cursor-pointer"
                                  onClick={() => handleRowClick(user.uid)}
                                >
                                  {user.firstName} {user.lastName}
                                </h6>
                                <span className="fw-normal">
                                  <a
                                    href={`mailto:${user.email}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="underLink"
                                  >
                                    {user.email}
                                  </a>
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <a
                              href="#"
                              className="mb-0 fw-normal fs-4 cursor-pointer"
                              onClick={() => filterByRole(user.role)}
                            >
                              {user.role ? capitalize(user.role) : "User"}
                            </a>
                          </td>
                          <td>
                            {(() => {
                              const lastLoginDate = user.lastLogin?.seconds
                                ? new Date(user.lastLogin.seconds * 1000)
                                : null;
                              const createdAtDate = user.createdAt?.seconds
                                ? new Date(user.createdAt.seconds * 1000)
                                : null;

                              const threeMonthsAgo = new Date();
                              threeMonthsAgo.setMonth(
                                threeMonthsAgo.getMonth() - 3
                              );

                              const sixMonthsAgo = new Date();
                              sixMonthsAgo.setMonth(
                                sixMonthsAgo.getMonth() - 6
                              );

                              let status = "Active";

                              if (lastLoginDate) {
                                if (lastLoginDate < sixMonthsAgo) {
                                  status = "Lost";
                                } else if (lastLoginDate < threeMonthsAgo) {
                                  status = "Inactive";
                                }
                              } else if (
                                createdAtDate &&
                                createdAtDate < threeMonthsAgo
                              ) {
                                status = "Lost";
                              }

                              const statusClass =
                                status === "Active"
                                  ? "bg-success-subtle text-success"
                                  : status === "Inactive"
                                  ? "bg-warning-subtle text-warning"
                                  : "bg-danger-subtle text-danger";

                              return (
                                <span className={`badge ${statusClass}`}>
                                  {status}
                                </span>
                              );
                            })()}
                          </td>
                          <td>
                            <p className="mb-0 fw-normal fs-4">
                              <a
                                href="#"
                                onClick={() => filterByGender(user.gender)}
                              >
                                {user.gender ?? ""}
                              </a>
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 fw-normal fs-4">
                              <a
                                href="#"
                                onClick={() =>
                                  filterUsersByProvince(
                                    user.settings?.preferedProvince
                                  )
                                }
                              >
                                {user.settings?.preferedProvince ?? ""}
                              </a>
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 fw-normal fs-4">
                              {user.lastLogin?.seconds
                                ? formatDistanceToNow(
                                    new Date(user.lastLogin.seconds * 1000),
                                    {
                                      addSuffix: true,
                                    }
                                  )
                                : "Never"}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 fw-normal fs-4">
                              {user.createdAt?.seconds
                                ? formatDistanceToNow(
                                    new Date(user.createdAt.seconds * 1000),
                                    {
                                      addSuffix: true,
                                    }
                                  )
                                : "Unknown"}
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 fw-normal fs-4">
                              <a
                                href="#"
                                onClick={() =>
                                  filterByPlan(user.userPlan ?? "Free")
                                }
                              >
                                {user.userPlan
                                  ? capitalize(user.userPlan)
                                  : "Free"}
                              </a>
                            </p>
                          </td>
                          <td>
                            <div className="dropdown dropstart">
                              <a
                                href="#"
                                className="text-muted"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="ti ti-dots-vertical fs-6"></i>
                              </a>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <li>
                                  <button
                                    className="dropdown-item d-flex align-items-center gap-3"
                                    onClick={() =>
                                      navigate(`/user/${user.uid}/edit`)
                                    }
                                  >
                                    <i className="fs-4 ti ti-edit"></i>Edit
                                  </button>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item d-flex align-items-center gap-3"
                                    href="#"
                                  >
                                    <i className="fs-4 ti ti-trash"></i>Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
