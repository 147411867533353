import React, { useEffect, useRef, useState } from "react";
import AppLogo from "../../../components/logo";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../stores/AuthContext";

function ForgotPassword({ email }) {

  const errorRef = useRef();
  const location = useLocation();
  const [currentEmail, setCurrentEmail] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const { resetPassword } = useAuth();

  useEffect(() => {
    // Parse the query parameters to extract the email
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    if (emailParam) {
      setCurrentEmail(emailParam);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);
    
    try {
      await resetPassword(currentEmail);
      setLoading(false);
      setSuccess("If your email is registered, you will receive a password reset link.");
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.error("Error during password reset:", error);
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-md-8 col-lg-6 col-xxl-3 auth-card">
        <div className="card mb-0">
          <div className="card-body pt-5">
            <a
              href="../dark/index.html"
              className="text-nowrap logo-img text-center d-block mb-4 w-100"
            >
              <AppLogo />
            </a>
            <div className="mb-5 text-center">
              <p className="mb-0 ">
                Please enter the email address associated with your account and
                we will email you a link to reset your password.
              </p>
            </div>
            <form id="passwordResetForm" onSubmit={handleSubmit}>
              {success && (
                <div className="alert alert-success" role="alert">
                  <strong>Success!</strong> {success}
                </div>
              )}
              {error && (
                <div ref={errorRef} className="alert alert-danger" role="alert">
                  <strong>Oops!</strong> {error}
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="emailAddress" className="form-label">
                  Email Address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  name="email"
                  defaultValue={currentEmail}
                  onChange={(e) => setCurrentEmail(e.target.value)}
                  required
                />
              </div>
              {loading ? (
                <button
                  className="btn btn-primary w-100 py-2 mb-3"
                  type="submit"
                  disabled
                >
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary w-100 py-8 mb-3"
                >
                  Forgot Password
                </button>
              )}
              <Link
                to="/login"
                className="btn bg-primary-subtle text-primary w-100 py-8"
              >
                Back to Login
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
