import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAYrbOG9c4iFDElWLrkcvI32s39JQLqjPY",
  authDomain: "funduro-za.firebaseapp.com",
  projectId: "funduro-za",
  storageBucket: "funduro-za.appspot.com",
  messagingSenderId: "624861625831",
  appId: "1:624861625831:web:872380840be761f0ba1142",
  measurementId: "G-KCSWPHZQ2Y",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app); 
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
