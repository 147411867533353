import { useAuth } from "../stores/AuthContext";

export const askGpt = async (command, data, apiKey) => {
  return await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    },
    body: JSON.stringify({
      model: "gpt-4",
      messages: [
        {
          role: "user",
          content: command,
        },
        {
          role: "user",
          content: data,
        },
      ],
      max_tokens: 100,
      temperature: 0.5,
      n: 1,
      stop: ["\n"],
    }),
  });
};
