import React from "react";
import Dashboard from "./features/dashboard/dashboard";
import Login from "./features/auth/login"; 
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./services/ProtectedRoute";
import Users from "./features/users/index/users";
import User from "./features/users/single/user";
import Events from "./features/events/list/events";
import Register from "./features/auth/register/register";
import ForgotPassword from "./features/auth/forgotPassword/forgotPassword";
import { useAuth } from "./stores/AuthContext";
import NotFound from "./features/404/404";
import NewEvent from "./features/events/new/new";
import EmailVerificationProtectedRoute from "./services/EmailVerificationProtected";
import EditEvent from "./features/events/edit/edit";
import EditUser from "./features/users/edit/edit";
import Marketplace from "./features/marketplace/list/marketplace";
import EditProduct from "./features/marketplace/edit/edit-product";

const AppRoutes = () => {
  const { userDetails } = useAuth();
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      {/* General Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Dashboard />} />

        <Route path="/events" element={<Events />} />
        <Route element={<EmailVerificationProtectedRoute />}>
          <Route path="/event/new" element={<NewEvent />} />
          <Route path="/event/:id/edit" element={<EditEvent />} />
        </Route>

        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/product/:id/edit" element={<EditProduct />} />
      </Route>

      {/* Admin Protected Routes */}
      {userDetails && userDetails.role === "admin" && (
        <Route element={<ProtectedRoute />}>
          <Route path="/users" element={<Users />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/user/:id/edit" element={<EditUser />} />
        </Route>
      )}

      {/* Fallback Route for 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
