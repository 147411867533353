import React, { useRef, useState, useEffect } from "react";
import { globalSettings } from "../../stores/GlobalContext";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../stores/AuthContext";
import AppLogo from "../../components/logo";

const Login = () => {
  document.querySelector("title").innerHTML =
    "Login | " + globalSettings.appName;

  const emailRef = useRef();
  const errorRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { login, isAuthenticated } = useAuth();

  function passwordObscure() {
    const passwordInput = document.getElementById("password");
    const passwordIcon = document.querySelector(".password-view-toggle i");
    passwordInput.type =
      passwordInput.type === "password" ? "text" : "password";
    // chanage icon based on password visibility
    passwordIcon.classList.toggle("fa-eye");
    passwordIcon.classList.toggle("fa-eye-slash");
  }

  useEffect(() => {
    emailRef.current.focus();
    document.getElementsByTagName("body")[0].classList.add("loginPage");
    document.getElementsByTagName("body")[0].classList.add("d-flex");
    document
      .getElementsByTagName("body")[0]
      .classList.add("align-items-center");
    document.getElementsByTagName("body")[0].classList.add("bg-body-tertiary");

    if (isAuthenticated) {
      document.getElementsByTagName("body")[0].classList.remove("loginPage");
      navigate("/");
    }
  });

  useEffect(() => {
    setError(null);
  }, [email, password]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      // Use login from AuthContext
      await login(email, password, rememberMe);
      // Redirect to dashboard after successful login
      setEmail("");
      setPassword("");
      setLoading(false);
      document.getElementsByTagName("body")[0].classList.remove("loginPage");
      navigate("/");
    } catch (err) {
      setLoading(false);
      if (err.code === "auth/invalid-credential") {
        setError("Invalid credentials. Please check your email and password.");
      } else if (err.code === "auth/user-disabled") {
        setError("This account has been locked. Please contact support help.");
      } else if (err.code === "auth/too-many-requests") {
        setError(
          "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
        );
      } else {
        setError(err);
      }
    }
  };

  return (
    <div className="row justify-content-center w-100">
      <div className="col-md-8 col-lg-6 col-xxl-3 auth-card">
        <div className="card mb-0">
          <div className="card-body">
            <Link
              to="/"
              className="text-nowrap logo-img text-center d-block w-100"
            >
              <AppLogo />
            </Link>
            <div className="position-relative text-center my-4">
              <p className="mb-0 fs-4 px-3 d-inline-block bg-body text-dark z-index-5 position-relative">
                Login
              </p>
              <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
            </div>
            <form id="loginForm" onSubmit={handleSubmit}>
              {error && (
                <div ref={errorRef} className="alert alert-danger" role="alert">
                  <strong>Oops!</strong> {error} <br />
                  {error.includes("support") && (
                    <a href={`mailto:${globalSettings.supportEmail}`}>
                      {globalSettings.supportEmail}
                    </a>
                  )}
                </div>
              )}

              <div className="mb-3">
                <label htmlFor="emailAddress" className="form-label">
                  Email Address
                </label>
                <input
                  ref={emailRef}
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <div className="input-group mb-0">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className="password-view-toggle input-group-text cursor-pointer"
                  onClick={passwordObscure}
                >
                  <i className="fa fa-eye"></i>
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
                <div className="form-check">
                  <input
                    className="form-check-input primary cursor-pointer"
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label
                    className="form-check-label text-dark cursor-pointer"
                    for="rememberMe"
                  >
                    Remeber me
                  </label>
                </div>
                <Link
                  className="text-primary fw-medium"
                  to={
                    email !== null && email !== ""
                      ? `/forgot-password?email=${email}`
                      : "/forgot-password"
                  }
                >
                  Forgot Password ?
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100 py-8 mb-4 rounded-2 d-flex align-items-center justify-content-center"
                disabled={loading}
              >
                {loading ? (
                  <div className="spin me-2">
                    <i className="ti ti-loader"></i>
                  </div>
                ) : null}
                Sign In
              </button>
              <div className="d-flex align-items-center justify-content-center">
                <p className="fs-4 mb-0 fw-medium">
                  Are you an event organiser?
                </p>
                <Link className="text-primary fw-medium ms-2" to="/register">
                  Sign Up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
