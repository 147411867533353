import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppLogo from "./logo";
import { useAuth } from "../stores/AuthContext";
import { capitalize } from "../utils/stringUtils";

function Sidebar({ handleMenu }) {
  const { userDetails, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <aside className="left-sidebar with-vertical">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          <Link to="/" className="text-nowrap logo-img">
            {/* <AppLogo /> */}
          </Link>
          <a
            href="/"
            className="sidebartoggler ms-auto text-decoration-none fs-5 d-block d-xl-none"
            onClick={handleMenu}
          >
            <i className="ti ti-x"></i>
          </a>
        </div>

        <nav className="sidebar-nav scroll-sidebar" data-simplebar>
          <ul id="sidebarnav">
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              <span className="hide-menu">Home</span>
            </li>

            <li className="sidebar-item">
              <Link
                to="/"
                className="sidebar-link"
                id="get-url"
                aria-expanded="false"
              >
                <span>
                  <i className="ti ti-home"></i>
                </span>
                <span className="hide-menu">Home</span>
              </Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/events" aria-expanded="false">
                <span>
                  <i className="ti ti-calendar"></i>
                </span>
                <span className="hide-menu">Events</span>
              </Link>
            </li>

            <li className="sidebar-item">
              <Link className="sidebar-link" to="/marketplace" aria-expanded="false">
                <span>
                  <i className="ti ti-building-store"></i>
                </span>
                <span className="hide-menu">Marketplace</span>
              </Link>
            </li>
            {userDetails && userDetails.role === "admin" && (
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/users"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-users"></i>
                  </span>
                  <span className="hide-menu">Users</span>
                </Link>
              </li>
            )}

            {/* {userDetails && userDetails.role === "admin" && ( 
              // <div>
              //   <li className="nav-small-cap">
              //     <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
              //     <span className="hide-menu">OTHER</span>
              //   </li>
              //   <li className="sidebar-item">
              //     <a
              //       href="/"
              //       className="sidebar-link has-arrow"
              //       aria-expanded="false"
              //     >
              //       <span className="d-flex">
              //         <i className="ti ti-box-multiple"></i>
              //       </span>
              //       <span className="hide-menu">Menu Level</span>
              //     </a>
              //     <ul className="collapse first-level">
              //       <li className="sidebar-item">
              //         <a href="/" className="sidebar-link">
              //           <div className="round-16 d-flex align-items-center justify-content-center">
              //             <i className="ti ti-circle"></i>
              //           </div>
              //           <span className="hide-menu">Level 1</span>
              //         </a>
              //       </li>
              //       <li className="sidebar-item">
              //         <a
              //           href="/"
              //           className="sidebar-link has-arrow"
              //           aria-expanded="false"
              //         >
              //           <div className="round-16 d-flex align-items-center justify-content-center">
              //             <i className="ti ti-circle"></i>
              //           </div>
              //           <span className="hide-menu">Level 1.1</span>
              //         </a>
              //         <ul className="collapse two-level">
              //           <li className="sidebar-item">
              //             <a href="/" className="sidebar-link">
              //               <div className="round-16 d-flex align-items-center justify-content-center">
              //                 <i className="ti ti-circle"></i>
              //               </div>
              //               <span className="hide-menu">Level 2</span>
              //             </a>
              //           </li>
              //           <li className="sidebar-item">
              //             <a
              //               href="/"
              //               className="sidebar-link has-arrow"
              //               aria-expanded="false"
              //             >
              //               <div className="round-16 d-flex align-items-center justify-content-center">
              //                 <i className="ti ti-circle"></i>
              //               </div>
              //               <span className="hide-menu">Level 2.1</span>
              //             </a>
              //             <ul className="collapse three-level">
              //               <li className="sidebar-item">
              //                 <a href="/" className="sidebar-link">
              //                   <div className="round-16 d-flex align-items-center justify-content-center">
              //                     <i className="ti ti-circle"></i>
              //                   </div>
              //                   <span className="hide-menu">Level 3</span>
              //                 </a>
              //               </li>
              //               <li className="sidebar-item">
              //                 <a href="/" className="sidebar-link">
              //                   <div className="round-16 d-flex align-items-center justify-content-center">
              //                     <i className="ti ti-circle"></i>
              //                   </div>
              //                   <span className="hide-menu">Level 3.1</span>
              //                 </a>
              //               </li>
              //             </ul>
              //           </li>
              //         </ul>
              //       </li>
              //     </ul>
              //   </li>
              //   <li className="sidebar-item">
              //     <a href="/" className="sidebar-link link-disabled"
              //       aria-expanded="false"
              //     >
              //       <span className="d-flex">
              //         <i className="ti ti-ban"></i>
              //       </span>
              //       <span className="hide-menu">Disabled</span>
              //     </a>
              //   </li>
              //   <li className="sidebar-item">
              //     <a href="/" className="sidebar-link" aria-expanded="false">
              //       <i className="ti ti-star"></i>
              //       <div className="hide-menu lh-base">
              //         <span className="hide-menu d-block">SubCaption</span>
              //         <span className="hide-menu d-block fs-2">
              //           This is the sutitle
              //         </span>
              //       </div>
              //     </a>
              //   </li>
              //   <li className="sidebar-item">
              //     <a href="/" className="sidebar-link justify-content-between"
              //       aria-expanded="false"
              //     >
              //       <div className="d-flex align-items-center gap-3">
              //         <span className="d-flex">
              //           <i className="ti ti-award"></i>
              //         </span>
              //         <span className="hide-menu">Chip</span>
              //       </div>
              //       <div className="hide-menu">
              //         <span className="badge rounded-circle bg-primary d-flex align-items-center justify-content-center rounded-pill fs-2">
              //           9
              //         </span>
              //       </div>
              //     </a>
              //   </li>
              //   <li className="sidebar-item">
              //     <a href="/" className="sidebar-link justify-content-between"
              //       aria-expanded="false"
              //     >
              //       <div className="d-flex align-items-center gap-3">
              //         <span className="d-flex">
              //           <i className="ti ti-mood-smile"></i>
              //         </span>
              //         <span className="hide-menu">Outlined</span>
              //       </div>
              //       <span className="hide-menu badge rounded-pill border border-primary text-primary fs-2 py-1 px-2">
              //         Outline
              //       </span>
              //     </a>
              //   </li>
              //   <li className="sidebar-item">
              //     <a
              //       className="sidebar-link"
              //       href="https://google.com"
              //       aria-expanded="false"
              //     >
              //       <span className="d-flex">
              //         <i className="ti ti-star"></i>
              //       </span>
              //       <span className="hide-menu">External Link</span>
              //     </a>
              //   </li>
              // </div>
            // )} */}
          </ul>
        </nav>

        <div className="fixed-profile p-3 mx-4 mb-2 bg-secondary-subtle rounded mt-3">
          <div className="hstack gap-3">
            <div className="john-img">
              <img
                src={
                  userDetails?.photoURL ??
                  `https://ui-avatars.com/api/?name=${userDetails?.firstName}+${userDetails?.lastName}&background=random&color=fff`
                }
                className="rounded-circle object-fit-cover"
                width="40"
                height="40"
                alt="modernize-img"
              />
            </div>
            <div className="john-title">
              <h6 className="mb-0 fs-2 fw-normal">
                {userDetails?.firstName} {userDetails?.lastName}
              </h6>
              <span className="fs-2">
                {userDetails?.role ? capitalize(userDetails?.role) : "User"}
              </span>
            </div>
            <a
              href="/"
              className="border-0 bg-transparent text-primary ms-auto"
              tabIndex="0"
              type="button"
              onClick={handleLogout}
            >
              <i className="ti ti-power fs-6"></i>
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
